import { useCallback, useState } from 'react'

export type UseStepsArgs = {
  totalSteps: number
  defaultStep?: number | null
}

const useSteps = ({ totalSteps, defaultStep = 0 }: UseStepsArgs) => {
  const [step, setStep] = useState<number | null>(defaultStep)

  const handleBack = useCallback(() => {
    setStep((step) => (step === null || step <= 0 ? 0 : step - 1))
  }, [])

  const lastStepIndex = totalSteps - 1

  const handleNext = useCallback(() => {
    setStep((step) =>
      step === null ? 0 : step === lastStepIndex ? lastStepIndex : step + 1
    )
  }, [lastStepIndex])

  const isLast = (step ?? 0) === totalSteps - 1

  return { step, handleBack, handleNext, setStep, isLast }
}

export default useSteps
