import { Box, Container, Stack, Typography } from '@mui/material'

import logo from 'assets/img/logo.svg'
import { interpolate, useTranslations } from 'common/services/translations'

import styles from '../styles'

export default function Footer() {
  const { gettext } = useTranslations()

  return (
    <Container
      maxWidth={false}
      sx={{
        mt: 'auto',
        background: 'rgba(13, 19, 50, 0.04)',
      }}
    >
      <Box px={2} py={3} sx={styles.wrapper}>
        <Stack direction="row" gap={3}>
          <Box component="img" src={logo} width="24px" height="24px" />
          <Stack gap={1.25}>
            <Typography variant="subtitle1" fontWeight={600}>
              {gettext('Need help?')}
            </Typography>
            <Typography
              color="text.secondary"
              sx={{ a: { color: 'inherit', textDecoration: 'underline' } }}
              dangerouslySetInnerHTML={{
                __html: interpolate(
                  gettext('%s and our study team will get back to you.'),
                  [
                    `<a href="/contact" target="_blank">${gettext('Contact us')}</a>`,
                  ]
                ),
              }}
            />
          </Stack>
        </Stack>
      </Box>
    </Container>
  )
}
