import { SxProps } from '@mui/material'

const styles: Record<string, SxProps> = {
  root: {
    minHeight: '100vh',
    display: 'flex',
    gap: '100px',
    flexDirection: 'column',
  },
  wrapper: {
    width: '100%',
    maxWidth: 728,
    margin: '0 auto',
  },
  content: {
    p: 3,
    display: 'flex',
    flexDirection: 'column',
  },
}

export default styles
