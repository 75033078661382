import { ReactNode } from 'react'
import { Box, Grid } from '@mui/material'
import { Form, FormikProps, FormikValues } from 'formik'
import textLogo from 'assets/img/text-logo.svg'

import styles from './styles'
import { useStepperContext } from '../context'
import {
  Error,
  Loading,
  StepperSuccess,
  StepperSuccessProps,
  StepWrapper,
  StepWrapperProps,
} from '.'

type Step = {
  component: () => JSX.Element
  fieldNames: string[]
}

export type StepContentProps = {
  steps: Step[]
  submitError?: string | string[] | null
  InitialComponent?: () => ReactNode
  successProps: StepperSuccessProps
  wrapperProps?: StepWrapperProps
}

export default function StepContent<T extends FormikValues>({
  steps,
  isSubmitting,
  submitError,
  InitialComponent,
  successProps,
  wrapperProps,
}: FormikProps<T> & StepContentProps) {
  const { step, success } = useStepperContext()

  // steps may be empty
  const StepComponent = (steps[step ?? 0] as Step | undefined)?.component

  return (
    <>
      {isSubmitting && <Loading />}
      {!isSubmitting && success && <StepperSuccess {...successProps} />}
      {!isSubmitting && !success && (
        <>
          <Box
            component="img"
            src={textLogo}
            alt="Elixir AI"
            width={140}
            height={24}
            mb={3}
          />
          {step === null && InitialComponent && <InitialComponent />}
          {step !== null && (
            <Form>
              <Grid container gap={1.75} my={4}>
                {steps.map((_, index) => (
                  <Box
                    key={index}
                    sx={{
                      ...styles.indicator,
                      ...(index <= step ? styles.passed : {}),
                    }}
                  />
                ))}
              </Grid>
              <Error error={submitError} />
              <StepWrapper {...wrapperProps}>
                {StepComponent && <StepComponent />}
              </StepWrapper>
            </Form>
          )}
        </>
      )}
    </>
  )
}
