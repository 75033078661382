import { ReactNode } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import success from 'assets/img/success.svg'

import Protection from './Protection'

export type StepperSuccessProps = {
  title: ReactNode
  description: ReactNode
}

export default function Success({ title, description }: StepperSuccessProps) {
  return (
    <>
      <Box
        component="img"
        src={success}
        alt="Success"
        width={80}
        height={100}
        mb={3}
      />
      <Typography variant="h5" fontWeight={600} mb={1.5}>
        {title}
      </Typography>
      <Typography variant="body1" color="text.secondary" mb={3}>
        {description}
      </Typography>
      <Protection />
    </>
  )
}
