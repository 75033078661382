import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState,
} from 'react'
import useSteps, { UseStepsArgs } from './useSteps'

type StepperContextType = ReturnType<typeof useSteps> & {
  success: boolean
  setSuccess: Dispatch<SetStateAction<boolean>>
}

const StepperContext = createContext<StepperContextType | null>(null)

type StepperProviderProps = PropsWithChildren & UseStepsArgs

export const StepperProvider = ({
  children,
  ...props
}: StepperProviderProps) => {
  const steps = useSteps(props)
  const [success, setSuccess] = useState(false)

  const value: StepperContextType = {
    ...steps,
    success,
    setSuccess,
  }

  return (
    <StepperContext.Provider value={value}>{children}</StepperContext.Provider>
  )
}

export const useStepperContext = () => {
  const context = useContext(StepperContext)
  if (!context) {
    throw new Error('useStepper must be used within a StepperProvider')
  }
  return context
}
