import { Grid, SxProps, Typography } from '@mui/material'

import { useTranslations } from 'common/services/translations'

import styles from './styles'

type Props = {
  sx?: SxProps
}

export default function Protection({ sx }: Props) {
  const { gettext } = useTranslations()

  return (
    <Grid container wrap="nowrap" gap={1} sx={{ ...styles.protection, ...sx }}>
      <Typography variant="body1">🔒</Typography>
      <Typography variant="body2" color="text.secondary">
        {gettext(
          'We care about your privacy. Data is encrypted in transit and at rest.'
        )}
      </Typography>
    </Grid>
  )
}
