import {
  Autocomplete,
  AutocompleteProps,
  AutocompleteRenderInputParams,
} from 'formik-mui'
import { isArray } from 'lodash-es'
import { TextField, TextFieldProps } from '@mui/material'

import { OptionValue } from '.'

export type StepperSelectFieldProps<Option extends Record<string, unknown>> =
  Omit<
    AutocompleteProps<AutocompleteValue, boolean, false, true>,
    'children' | 'options'
  > & {
    options: Option[]
    valueKey?: keyof Option
    labelKey?: keyof Option
    groupKey?: keyof Option
    placeholder?: string
    InputProps?: TextFieldProps
  }

export type AutocompleteValue = {
  label: string
  value: string
  disabled?: boolean
}

export default function StepperSelectField<
  Option extends Record<string, OptionValue>,
>({
  placeholder,
  valueKey = 'value',
  labelKey = 'label',
  groupKey = 'group',
  InputProps,
  ...props
}: StepperSelectFieldProps<Option>) {
  const options: AutocompleteValue[] = [
    ...(placeholder ? [{ label: placeholder, disabled: true, value: '' }] : []),
    ...props.options.map((option) => ({
      value: String(option[valueKey]),
      label: String(option[labelKey]),
      group: option[groupKey] ? String(option[groupKey]) : undefined,
    })),
  ]

  const renderInput = (renderParams: AutocompleteRenderInputParams) => {
    const { form, field } = props

    const error = form.touched[`${field.name}-input`] && form.errors[field.name]
    const helperText = error
      ? String(isArray(error) ? error[0] : error)
      : InputProps?.helperText

    return (
      <TextField
        // We need this name because formik treats this as field that user touches
        name={`${field.name}-input`}
        variant="outlined"
        {...renderParams}
        {...InputProps}
        error={!!error}
        helperText={helperText}
      />
    )
  }

  return (
    <Autocomplete<AutocompleteValue, boolean, false, true>
      {...props}
      options={options}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      getOptionDisabled={(option) => Boolean(option.disabled)}
      renderInput={props.renderInput ?? renderInput}
    />
  )
}
