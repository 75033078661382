import { keys } from 'lodash-es'
import { Formik, FormikHelpers, FormikValues } from 'formik'

import useFormSubmit from 'common/hooks/useFormSubmit'

import { useStepperContext } from '../context'
import { StepContent, StepperContentProps } from '.'

export type StepperFormProps<
  FormValues extends FormikValues,
  SubmitValues extends Record<string, unknown>,
> = {
  alwaysFormData?: boolean
  initialValues: FormValues
  validationSchema: unknown[]
  formSubmitArgs: Parameters<typeof useFormSubmit>
  onSubmit: (formValues: FormValues) => SubmitValues
  contentProps: Omit<StepperContentProps, 'submitError'>
}

export default function StepperForm<
  T extends FormikValues,
  S extends Record<string, unknown>,
>({
  initialValues,
  validationSchema,
  formSubmitArgs,
  contentProps,
  onSubmit: handleSubmit,
  alwaysFormData = false,
}: StepperFormProps<T, S>) {
  const { step, setStep, setSuccess } = useStepperContext()

  const { generalError, submitForm } = useFormSubmit<T, unknown, S>(
    ...formSubmitArgs
  )

  const onSuccess = () => {
    setSuccess(true)
  }

  const onError = (error: { data?: object }) => {
    keys(error?.data || {}).forEach((key) => {
      const stepIndex = contentProps.steps.findIndex(({ fieldNames }) =>
        fieldNames.includes(key)
      )
      if (stepIndex !== -1) {
        setStep(stepIndex)
      }
    })
  }

  const onSubmit = (values: T, actions: FormikHelpers<T>) => {
    const data = handleSubmit(values)

    return submitForm({
      data,
      actions,
      onSuccess,
      onError,
      headers: alwaysFormData
        ? {
            'Content-Type': 'multipart/form-data',
          }
        : undefined,
    })
  }

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema[step ?? 0]}
    >
      {(props) => (
        <StepContent submitError={generalError} {...contentProps} {...props} />
      )}
    </Formik>
  )
}
