import { Box, CircularProgress } from '@mui/material'
import { FormikValues } from 'formik'

import styles from './styles'
import { StepperProvider } from './context'
import {
  FormError,
  StepperFooter,
  StepperForm,
  StepperFormProps,
} from './components'

type StepperProps<
  FormValues extends FormikValues,
  SubmitValues extends Record<string, unknown>,
> = {
  defaultStep?: number | null
  requestError?: Error | null
  isLoading: boolean
  formProps: Omit<
    StepperFormProps<FormValues, SubmitValues>,
    'children' | 'step'
  >
}

export default function Stepper<
  T extends FormikValues,
  S extends Record<string, unknown>,
>({ defaultStep, formProps, requestError, isLoading }: StepperProps<T, S>) {
  if (isLoading) {
    return (
      <Box
        height="100vh"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress />
      </Box>
    )
  }

  return (
    <StepperProvider
      defaultStep={defaultStep}
      totalSteps={formProps.contentProps.steps.length}
    >
      <Box sx={styles.root}>
        <Box sx={styles.wrapper}>
          <Box sx={styles.content}>
            {Boolean(requestError) && <FormError />}
            {!requestError && <StepperForm {...formProps} />}
          </Box>
        </Box>
        <StepperFooter />
      </Box>
    </StepperProvider>
  )
}
