import { lighten } from '@mui/material/styles'

import variables from 'assets/styles/_variables.module.scss'

const styles = {
  protection: {
    p: 1.5,
    backgroundColor: lighten(variables.primaryMain, 0.96),
    borderRadius: 1,
  },
  indicator: {
    height: 6,
    flexGrow: 1,
    borderRadius: '3px',
    backgroundColor: lighten(variables.primaryMain, 0.7),
  },
  passed: {
    backgroundColor: 'secondary.light',
  },
}

export default styles
