import { ReactNode } from 'react'
import { Alert, AlertTitle } from '@mui/material'

import { useTranslations } from 'common/services/translations'

type Props = {
  error?: ReactNode
}

export default function Error({ error }: Props) {
  const { gettext } = useTranslations()

  return (
    error && (
      <Alert severity="error" color="error" sx={{ mb: 2 }}>
        <AlertTitle>{gettext('Error')}</AlertTitle>
        {error}
      </Alert>
    )
  )
}
