import { Box, Paper, Typography } from '@mui/material'

import { interpolate, useTranslations } from 'common/services/translations'
import textLogo from 'assets/img/text-logo.svg'

export default function FormError() {
  const { gettext } = useTranslations()

  return (
    <Box>
      <Box
        component="img"
        src={textLogo}
        alt="Elixir AI"
        width={140}
        height={24}
        mb={4.5}
      />
      <Paper elevation={6} sx={{ p: 3, borderRadius: 2 }}>
        <Typography variant="h5" fontWeight={600} mb={2}>
          {gettext('Form Link Expired')}
        </Typography>
        <Typography
          variant="body1"
          color="text.secondary"
          sx={{
            a: {
              color: 'inherit',
              textDecoration: 'underline',
            },
          }}
          dangerouslySetInnerHTML={{
            __html: interpolate(
              gettext('Looks like the link has expired. %s to resend.'),
              [
                `<a href="https://elixir.ai/contact-us/" target="_blank">${gettext('Contact Us')}</a>`,
              ]
            ),
          }}
        />
      </Paper>
    </Box>
  )
}
