import Lottie from 'lottie-react'
import { Box, Typography } from '@mui/material'

import { useTranslations } from 'common/services/translations'
import loading from 'assets/img/lottie/loading.json'

const style = {
  width: 80,
  height: 80,
}

export default function Loading() {
  const { gettext } = useTranslations()

  return (
    <>
      <Box
        component={Lottie}
        animationData={loading}
        loop
        style={style}
        mb={2}
      />
      <Typography variant="h5" fontWeight={500} mb={1.5}>
        {gettext(
          'Please avoid closing this tab to ensure a successful submission.'
        )}
      </Typography>
      <Typography variant="body1" color="text.secondary">
        {gettext(
          'Submission may take time due to file uploading. Thank you for your patience.'
        )}
      </Typography>
    </>
  )
}
