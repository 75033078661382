import { PropsWithChildren, useEffect } from 'react'
import { isEmpty } from 'lodash-es'
import { useFormikContext } from 'formik'
import { Button, Grid } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CheckIcon from '@mui/icons-material/Check'

import { useTranslations } from 'common/services/translations'

import { useStepperContext } from '../context'

export type StepWrapperProps = {
  submitText?: string
  nextText?: string
}

export default function StepWrapper({
  children,
  nextText,
  submitText,
}: PropsWithChildren<StepWrapperProps>) {
  const { step, handleBack, handleNext, isLast } = useStepperContext()
  const { gettext } = useTranslations()
  const { isValid, validateForm, submitForm } =
    useFormikContext<Record<string, string>>()

  const handleNextStep = async () => {
    const errors = await validateForm()

    if (isEmpty(errors)) {
      handleNext()
    }
  }

  useEffect(() => {
    validateForm()
  }, [step, validateForm])

  const buttonText = isLast
    ? submitText ?? gettext('Submit')
    : nextText ?? gettext('Next')

  return (
    <>
      {children}
      <Grid container gap={1.25} mt={4}>
        {step !== 0 && (
          <Button
            type="button"
            size="large"
            startIcon={<ArrowBackIcon />}
            onClick={handleBack}
          >
            {gettext('Back')}
          </Button>
        )}
        <Button
          size="large"
          type="button"
          variant="contained"
          disabled={!isValid}
          sx={{ flexGrow: 1 }}
          onClick={isLast ? submitForm : handleNextStep}
          startIcon={isLast ? <CheckIcon /> : undefined}
        >
          {buttonText}
        </Button>
      </Grid>
    </>
  )
}
