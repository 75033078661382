export default function getSignature() {
  const pathname = window.location.pathname
  const parts = pathname.split('/').filter((part) => part !== '')

  if (parts.length > 0) {
    return parts[parts.length - 1]
  } else {
    return ''
  }
}
